import { useState, useEffect } from 'react';

interface ISize { width?: number; height?: number }

export function useWindowResizeCallback(callback: (size?: ISize) => void) {
  useEffect(() => {
    function handleResize() {
      callback?.({
        width: window.innerWidth,
        height: window.innerHeight
      } as ISize);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [callback]);
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<ISize>({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      } as ISize);
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}
