import { BadgeCheck } from 'heroicons-react';
import React from 'react';
import { btnPrimary } from '../components/common/button/Button';

export const PendingUserPage: React.FC = () => {
  return <div className="w-full h-full flex items-center justify-center p-5">
    <div className="ml-2">
      <h1 className="text-5xl text-gray-700 flex items-center">
        <BadgeCheck className="mt-1 mr-2 flex-shrink-0 text-blue-300 opacity-75 self-start" size={52} />
        <span>Thanks for signing up!</span>
      </h1>
      <p className="mt-1 flex itemx-center text-2xl text-gray-600">
        <BadgeCheck className="mr-3 invisible flex-shrink-0" size={52} />
        <span>Please give us time to review your registration, and check back soon.</span>
      </p>
      <p className="mt-12 flex items-center justify-center opacity-75">
        <a {...btnPrimary} href="/login">Login</a>
      </p>
    </div>
  </div>;
};
