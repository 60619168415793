import { IPool } from '@contracts/auth/IPool';
import { ISite } from '@contracts/sites/ISite';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Else, If, Then } from 'react-if';
import { animationFrameScheduler, EMPTY, Subject } from 'rxjs';
import { catchError, last, observeOn, takeUntil, tap } from 'rxjs/operators';
import { dnsTest } from '../../data/operations/operations-service';
import { saveSite, uploadFile } from '../../data/sites-service';
import { UserSessionContext } from '../../UserSessionContext';
import { btnPrimary, btnSecondary, btnTertiary } from '../common/button/Button';
import { FileDropInput } from '../common/input/FileDropInput';
import { Modal } from '../common/modal/Modal';
import { For } from '../common/react/For';
import { Spinner } from '../common/spinner/Spinner';
import { fancySearch } from '../common/typeahead/fancy-search';
import { TypeaheadControl } from '../common/typeahead/TypeaheadControl';
import { addToPlayground } from '../playground/addToPlayground';

interface SiteFormValues {
  title: string;
  path: string;
  description?: string;
  category: string;
  whitelist?: string;
}

export interface ISiteFormModalProps {
  siteToEdit?: ISite;
  availablePools: IPool[];
  availableCategories: string[];

  onClosed?(): void;

  //for testing
  step?: Step;
  isOpen?: boolean;
  dismissible?: boolean;
}

type Step = 'DETAILS' | 'UPLOAD';

export function SiteFormModal(props: ISiteFormModalProps) {
  const steps: Step[] = ['DETAILS', 'UPLOAD'];
  const [step, setStep] = useState<Step>(props.step ?? steps[0]);
  const [isModalOpen, setIsModalOpen] = useState(props.isOpen ?? true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [siteData, setSiteData] = useState<ISite>();
  const cancelUpload$ = new Subject<{}>();

  function onCancel() {
    cancelUpload$.next({});
    setIsModalOpen(false);
    props.onClosed?.();
  }

  function postFile(files: File[]) {
    uploadFile(siteData!.id, files).pipe(
      observeOn(animationFrameScheduler),
      tap(progress => setUploadProgress(progress)),
      last(),
      tap((finalProgress: number) => {
        if (finalProgress === 100) {
          props.onClosed?.();
        }
      }),
      takeUntil(cancelUpload$)
    ).subscribe();
  }

  const DetailsStep = () => {
    const userSession = useContext(UserSessionContext);
    const [suggestions, setSuggestions] = useState<string[]>(props.availableCategories ?? []);
    const [isPublic, setIsPublic] = useState(props.siteToEdit?.isPublic === true ?? false);
    const { register, handleSubmit, errors, setError, control, watch } = useForm<SiteFormValues>({
      defaultValues: {
        title: props.siteToEdit?.title ?? '',
        path: props.siteToEdit?.path ?? '',
        description: props.siteToEdit?.description,
        category: props.siteToEdit?.category ?? '',
        whitelist: props.siteToEdit?.whitelist?.join('\n')
      }
    });
    const { whitelist: whiteListFormValue } = watch<'whitelist'>(['whitelist']);
    const [dnsTestResults, setDnsTestResults] = useState<string>('');

    const initialPermissionsValue = useMemo(() =>
        props.availablePools.reduce((accum, pool) => ({
          ...accum,
          [pool.id]: props.siteToEdit?.permissions?.[pool.id] ?? false
        }), {} as Record<string, boolean>),
      []
    );

    const [permSelection, setPermSelection] = useState<Record<string, boolean>>(initialPermissionsValue);
    const dnsResultRef = useRef<HTMLPreElement>(null);

    const filteredSuggestions = (val = ''): void => {
      setSuggestions(val ? fancySearch(val.trim(), props.availableCategories) : props.availableCategories);
    };

    function onSiteSubmit(formValue: SiteFormValues) {
      // eslint-disable-next-line no-restricted-globals
      event?.preventDefault(); // note, possibly prevents a form disconnected error

      formValue.path = encodeURIComponent(
        formValue.path
          .replace(/\//g, '')
          .replace(/\\/g, '')
      );

      const siteToSave = {
        ...formValue,
        whitelist: formValue.whitelist?.split('\n').map(val => val.trim()).filter(Boolean),
        permissions: permSelection,
        id: props.siteToEdit?.id,
        isPublic: isPublic ?? false
      };
      console.log('siteToSave', siteToSave);

      saveSite(siteToSave).pipe(
        tap(saveResult => {
          if (saveResult.result === 'SUCCESS') {
            setSiteData(saveResult.site);
            setStep('UPLOAD');
          } else if (saveResult.result === 'BAD_FIELDS') {
            for (const [field, error] of Object.entries(saveResult.fields)) {
              setError(field as keyof SiteFormValues, { message: error.displayReason });
            }
          }
        })
      ).subscribe();
    }

    function onTestWhitelistClick() {
      dnsTest(whiteListFormValue?.split('\n').filter(Boolean)).pipe(
        tap(console.debug),
        tap(results => {
          setDnsTestResults(JSON.stringify(results, null, 2));
          dnsResultRef.current?.scrollIntoView();
        }),
        catchError(error => {
          alert(`${error?.statusCode ?? ''} ${error?.message}`);
          return EMPTY;
        })
      ).subscribe();
    }

    return <div className="w-full py-5">
      <form className="flex flex-col h-full" onSubmit={handleSubmit(onSiteSubmit)}> {/*place-content-stretch*/}
        <div className="flex-none px-5">
          <h2 className="text-4xl">{props.siteToEdit ? 'Edit' : 'New'} Site Info</h2>
        </div>

        <div className="flex-grow flex flex-col mt-4 px-5 overflow-y-auto">
          <div className="flex flex-col">
            <label className="text-2xl" htmlFor="">Title</label>
            <input className="border-black border-b border-solid"
                   ref={register({ required: 'Required' })}
                   type="text"
                   name="title" id=""/>
            <ErrorMessage
              errors={errors}
              name="title"
              render={({ message }) =>
                <span className="text-red-500">{message}</span>}
            />

            <label className="text-2xl mt-4" htmlFor="">Link Path</label>
            <input
              className="border-black border-b border-solid"
              placeholder="The path where this site will be located (eg: my-cool-site)"
              ref={register({
                required: 'Required',
                validate: value => !(/[/\\?%*:|"<>]/g).test(value) || 'Path cannot contain: ? % * : | \\ /'
              })}
              type="text"
              name="path" id=""
            />
            <ErrorMessage errors={errors} name="path" render={thing =>
              <span className="text-red-500">{thing.message}</span>
            }/>

            <label className="text-2xl mt-4" htmlFor="description">Description</label>
            <textarea className="border-black border-b border-solid"
                      ref={register()} name="description"
                      placeholder="(Optional)"/>

            <label className="text-2xl mt-4" htmlFor="tags">Category</label>
            <TypeaheadControl
              suggestionProps={{
                suggestions: suggestions,
                onSuggestionsFetchRequested: ({ value }) => filteredSuggestions(value),
                onSuggestionsClearRequested: () => setSuggestions([]),
                renderInputComponent: inputProps =>
                  <input {...inputProps as any}
                         className="mt-2 border-black border-b border-solid w-full"
                         placeholder="(Optional) Any arbitrary text to help find this site when filtering."
                         type="text"/>,
                getSuggestionValue: val => val,
                shouldRenderSuggestions: () => true,
                renderSuggestion: (val, { isHighlighted, query }) =>
                  <div className={`
                         ${isHighlighted || query === val ? 'bg-yellow-200 bg-opacity-50 font-semibold' : ''}
                         my-1 p-1 pl-3
                         bg-gray-100 bg-opacity-50
                         shadow-xs rounded border-b border-gray border-solid
                         cursor-pointer
                         hover:bg-yellow-200 hover:font-semibold hover:bg-opacity-50
                  `}>
                    {val}
                  </div>
              }}
              controlProps={{
                name: 'category',
                control: control
              }}/>
            <ErrorMessage
              errors={errors}
              name="category"
              render={({ message }) =>
                <span className="text-red-500">{message}</span>}
            />

            <label className="text-2xl mt-4" htmlFor="">Permissions</label>
            <div className="flex items-center text-xl text-gray-700 mt-1">
              <label className="cursor-pointer">
                <input type="radio"
                       name="access"
                       checked={!isPublic}
                       onChange={() => setIsPublic(false)}/>
                <span className="ml-2 hover:underline">Private</span>
              </label>

              <label className="cursor-pointer ml-3">
                <input type="radio"
                       name="access"
                       checked={isPublic}
                       onChange={() => setIsPublic(true)}/>
                <span className="ml-2 hover:underline">Public</span>
              </label>
            </div>

            <div className="mt-1">
              <If condition={!isPublic}><Then>
                <div className="text-gray-500 text">These user pools will have access to this site.</div>
                <ul className="mt-2">
                  <For items={props.availablePools} render={pool =>
                    <li className="mb-2 cursor-pointer" key={pool.id}>
                      <label className="flex items-center cursor-pointer hover:underline"
                             htmlFor={`pool-select-${pool.name}`}>
                        <input id={`pool-select-${pool.name}`}
                               type="checkbox"
                               checked={permSelection[pool.id] ?? false}
                               onChange={e => setPermSelection({
                                 ...permSelection,
                                 [pool.id]: e.target.checked
                               })}
                        />
                        <span className="flex items-center ml-3">{pool.name} ({pool.id})</span>
                      </label>
                    </li>
                  }/>
                </ul>
              </Then><Else>
                <div className="text-yellow-600 text font-bold tracking-wider">
                  Anyone can reach this site. Login is not required.
                </div>
              </Else></If>
            </div>

            <label className="text-2xl mt-4" htmlFor="">Whitelist</label>
            <div className="text-gray-500 text">Enter IP addresses or domains separated by new lines. This site will
              only be accessible by requests made from the provided IPs/domains (leave blank for no whitelisting).
            </div>
            <div className="mt-1 w-full flex-col">
              <div className="w-full relative">
                <textarea className="w-full p-2 border border-solid border-black"
                          ref={register()}
                          name="whitelist"
                          rows={7}
                          placeholder={'(Optional)\nExample:\n12.345.678.910 \napp.process-solutions-group.com'}
                />
                <If condition={!!whiteListFormValue}>
                  <button type="button"
                          className={classNames(btnTertiary.className, 'absolute right-0 bottom-0 mr-2 mb-3')}
                          onClick={onTestWhitelistClick}
                  >
                    Test
                  </button>
                </If>
              </div>
              <If condition={!!userSession?.userInfo?.ip}>
                <div className="text-gray-600 text">
                  Here's your current IP for testing convenience: <span>{userSession?.userInfo?.ip?.join(', ')}</span>
                </div>
              </If>
              <pre ref={dnsResultRef} className="text-gray-700 bg-gray-200">{dnsTestResults}</pre>
            </div>
          </div>
        </div>

        <div className="flex-none flex justify-end mt-4 px-5">
          <span>
            <button {...btnSecondary} type="button" onClick={onCancel}>Cancel</button>
          </span>
          <span className="ml-2">
            <button {...btnPrimary} type="submit" disabled={!errors}>Next</button>
          </span>
        </div>
      </form>
    </div>;
  };

  const UploadStep = () => {
    const FileInput = () => <FileDropInput dropZoneOptions={{}} onFiles={postFile} multiple={true}/>;
    return <div>
      <If condition={uploadProgress > 0}>
        <Then>
          {/*<button {...btnSecondary} onClick={onCancel}>Cancel</button>*/}
          <span><Spinner/>Progress {uploadProgress}</span>
        </Then>
        <Else>
          <FileInput/>
        </Else>
      </If>
      <If condition={!!props.siteToEdit && uploadProgress === 0}>
        <span className="mr-3">
          <button {...btnSecondary} onClick={() => onCancel()}>Skip</button>
        </span>
      </If>
    </div>;
  };

  return (
    <Modal
      cssClass="absolute"
      isOpen={isModalOpen}
      onDidDismiss={() => onCancel()}
      backdropDismiss={props.dismissible ?? step === 'DETAILS'}
      keyboardClose={props.dismissible ?? step === 'DETAILS'}
    >
      {{
        'DETAILS': <DetailsStep/>,
        'UPLOAD': <UploadStep/>
      }[step]}
    </Modal>
  );
}

addToPlayground({
  name: SiteFormModal.name,
  cases: [
    {
      title: 'Upload Step', render: function UploadStep() {
        const [isOpen, setIsOpen] = useState(true);
        return <>
          <button type="button" onClick={() => setIsOpen(true)}>Open</button>
          <If condition={isOpen}>
            <SiteFormModal
              isOpen={isOpen}
              availablePools={[{ id: '123', name: 'My Pool', authorizeUrl: '', loginUrl: '' }]}
              availableCategories={['asfd', 'asdffd']}
              dismissible={true}
              onClosed={() => setIsOpen(false)}
              step="UPLOAD"/>
          </If>
        </>;
      }
    }
  ]
});
