export const btnPrimary = {
  className: 'bg-blue-900 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded'
}

export const btnSecondary = {
  className: 'bg-gray-500 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded'
}

export const btnTertiary = {
  className: 'bg-gray-400 hover:bg-gray-500 text-white font-bold py-1 px-3 rounded'
}

export const btnDanger = {
  className: 'bg-red-700 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
}
