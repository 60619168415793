import React from 'react';
import Autosuggest, { AutosuggestProps} from 'react-autosuggest';
import { useController, UseControllerOptions } from 'react-hook-form';

export interface ITypeaheadProps<T, R> {
  suggestionProps: Omit<AutosuggestProps<T, R>, 'inputProps'>;
  controlProps: UseControllerOptions<Record<string, any>>;
}

export const TypeaheadControl: React.FC<ITypeaheadProps<any, any>> = (props) => {
  const { field } = useController(props.controlProps);
  return (
    <Autosuggest
      {...(props.suggestionProps as any)}
      inputProps={{
        ...field,
        onChange: (event, { newValue }) => {
          field.onChange(newValue);
        }
      }}
    />
  );
};
