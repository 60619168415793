import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import '@ionic/react/css/core.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
//import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { CogOutline, CollectionOutline, UsersOutline } from 'heroicons-react';
import React, { Suspense, useEffect } from 'react';
import { Else, If, Then } from 'react-if';
import { Redirect, Route } from 'react-router-dom';
import { of } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { catchError, map } from 'rxjs/operators';
import { FullScreenLoading } from './components/common/FullScreenLoading';
import { getMyInfo } from './data/user-service';
import { userSession$ } from './data/user-session';
import { Authed, INavItem } from './pages/authed/Authed';
import { AuthGuardedRoute } from './pages/authed/AuthGuardedRoute';
import { SiteManagerPage } from './pages/authed/operations/sites/SiteManagerPage';
import { SitesTrafficPage } from './pages/authed/operations/sites/SitesTrafficPage';
import { ToolsPage } from './pages/authed/operations/ToolsPage';
import { UserPoolsPage } from './pages/authed/operations/UserPoolsPage';
import { SiteDirectoryPage } from './pages/authed/SiteDirectoryPage';
import { FormResultPage } from './pages/FormResultPage';
import { LoginPage } from './pages/LoginPage';
import { PendingUserPage } from './pages/PendingUserPage';
import './theme/variables.css';
import { IUserSessionContext, UserSessionContext } from './UserSessionContext';

const directoryNav = { displayText: 'Directory', path: '/directory' };
const operationNav: INavItem = {
  displayText: 'Operations',
  path: '/operations/sites',
  subNavItems: [
    {
      displayText: 'Site Manager',
      path: '/operations/sites',
      icon: <CollectionOutline/>,
    },
    {
      displayText: 'Site Traffic',
      path: '/operations/sites-traffic',
      icon: <CollectionOutline className="invisible"/>
    },
    {
      displayText: 'User Pools',
      path: '/operations/users',
      icon: <UsersOutline/>
    },
    {
      displayText: 'Tools',
      path: '/operations/tools',
      icon: <CogOutline/>
    }
  ]
};

const LazyPlayground = React.lazy(() => import('./components/playground/Playground'));

export const App: React.FC = () => {
  const navs = useObservable(() => userSession$.pipe(
    map(myInfo => {
      if (!myInfo) {
        return [];
      }
      return [
        myInfo.features.sites ? directoryNav : undefined,
        myInfo.features.operations ? operationNav : undefined
      ].filter(Boolean) as INavItem[];
    })
  ), [] as INavItem[]);

  const userInfoState = useObservable(() => userSession$.pipe(
    map(myInfo => {
      console.log(myInfo);
      return {
        isLoading: false,
        userInfo: myInfo
      };
    }),
    catchError((e) => {
      return of({
        isLoading: false
      });
    })
  ), { isLoading: true } as IUserSessionContext);

  useEffect(() => {
    const sub = getMyInfo().subscribe();
    return () => sub.unsubscribe();
  }, []);

  const MainContainerWithNav = (props) => <Authed navItems={navs}>{props.children}</Authed>;

  // todo constants for these url paths
  return <IonApp>
    <UserSessionContext.Provider value={userInfoState}>
      <If condition={!userInfoState.isLoading}>
        <Then>
          <IonReactRouter>
            <IonRouterOutlet>
              {/* Unauthenticated */}
              <Route exact path="/login" component={LoginPage}/>

              {/*Pending User Confirmation*/}
              <Route exact path="/signup-pending" component={PendingUserPage}/>

              {/* User Sites Directory */}
              <AuthGuardedRoute path="/directory" exact={true} render={() =>
                <MainContainerWithNav>
                  <SiteDirectoryPage/>
                </MainContainerWithNav>
              }/>

              {/* Ops */}
              <Route exact path="/operations" render={() => <Redirect to="/operations/sites"/>}/>
              <AuthGuardedRoute path="/operations/sites" exact={true} render={() =>
                <MainContainerWithNav>
                  <SiteManagerPage/>
                </MainContainerWithNav>
              }/>
              <AuthGuardedRoute path="/operations/sites-traffic" exact={true} render={() =>
                <MainContainerWithNav>
                  <SitesTrafficPage/>
                </MainContainerWithNav>
              }/>
              <AuthGuardedRoute path="/operations/users" exact={true} render={() =>
                <MainContainerWithNav>
                  <UserPoolsPage/>
                </MainContainerWithNav>
              }/>
              <AuthGuardedRoute path="/operations/tools" exact={true} render={() =>
                <MainContainerWithNav>
                  <ToolsPage/>
                </MainContainerWithNav>
              }/>

              <AuthGuardedRoute exact path="/form-result" render={() =>
                <MainContainerWithNav>
                  <FormResultPage/>
                </MainContainerWithNav>
              }/>

              <Route exact path="/playground" render={() =>
                <Suspense fallback={<FullScreenLoading/>}>
                  <LazyPlayground/>
                </Suspense>
              }/>

              {/* Redirects */}
              <Route exact path="/" render={() => <Redirect to="/directory"/>}/>
            </IonRouterOutlet>
          </IonReactRouter>
        </Then>
        <Else>
          <FullScreenLoading/>
        </Else>
      </If>
    </UserSessionContext.Provider>
  </IonApp>;
};
