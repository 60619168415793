import { ReplaySubject } from 'rxjs';

export interface IPlaygroundCase {
  title?: string;
  description?: string;
  render: () => JSX.Element;
}

export interface IPlayground {
  name: string;
  cases: IPlaygroundCase[];
}

export const playgrounds$ = new ReplaySubject<IPlayground[]>(1);

const _playgrounds: IPlayground[] = [];

export function addToPlayground(toAdd: IPlayground) {
  if (_playgrounds.find(p => p.name === toAdd.name)) {
    _playgrounds.length = 0;
  }
  _playgrounds.push(toAdd);
  _playgrounds.sort((a, b) => a.name.localeCompare(b.name));
  playgrounds$.next(_playgrounds);
}
