import { IUserInfo } from '@contracts/auth/IUserInfo';
import React from 'react';

export interface IUserSessionContext {
  isLoading: boolean;
  userInfo?: IUserInfo;
}

export const UserSessionContext = React.createContext<IUserSessionContext>({ isLoading: true });
UserSessionContext.displayName = 'UserInfoContext';
