import { IPool } from '@contracts/auth/IPool';
import React, { useMemo } from 'react';

export interface IPoolSelectStepProps {
  email: string;
  pools: IPool[];
  onPoolSelect: (selectedPoolId: string) => void;
}

export const PoolSelectStep: React.FC<IPoolSelectStepProps> = (props) => {
  const sortedPools = useMemo(() => {
    props.pools.sort((p1, p2) => p1.name.localeCompare(p2.name));
    return props.pools;
  }, [props.pools]);
  return (
    <div className="flex justify-center items-center h-full">
      <div className="
             max-w-full
             bg-white
             shadow-md
             rounded
             px-8 pt-6 pb-8 mb-4
             flex flex-col"
      >
        <div className="mb-4">
          <div className="text-xl md:text-3xl">{props.email}</div>
        </div>
        <div className="flex flex-col items-stretch justify-between">
          {sortedPools.map(pool =>
            <button key={pool.id}
                    className="bg-blue-900
                      hover:bg-blue-800
                      text-white font-bold
                      py-2 px-4
                      my-2
                      rounded"
                    type="button"
                    onClick={() => props.onPoolSelect(pool.id)}
            >
              {pool.name}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
