import { IUserInfo } from '@contracts/auth/IUserInfo';
import { Observable } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { API_BASE_PATH } from './api-props';
import { authedRequest$, mapToResponse } from './request-helper';
import { userSession$ } from './user-session';

const USER_PATH = `${API_BASE_PATH}/authentication/user`;

export function getMyInfo(): Observable<IUserInfo> {
  return authedRequest$({ url: USER_PATH, method: 'GET' }).pipe(
    retry(1),
    mapToResponse<IUserInfo>(),
    tap((userInfo: IUserInfo) => userSession$.next(userInfo))
  );
}
