import { IPool } from '@contracts/auth/IPool';
import { ISite } from '@contracts/sites/ISite';
import { GridApi, RowNode } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ExternalLinkOutline, LockClosedOutline } from 'heroicons-react';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { If } from 'react-if';
import { EMPTY, from } from 'rxjs';
import { catchError, concatMap, finalize, tap } from 'rxjs/operators';
import { btnDanger, btnPrimary } from '../../../../components/common/button/Button';
import { useWindowResizeCallback } from '../../../../components/common/hooks/windowResize';
import { Spinner } from '../../../../components/common/spinner/Spinner';
import { BatchSitePermissionsModal } from '../../../../components/sites/BatchSitePermissionModal';
import { SiteFormModal } from '../../../../components/sites/SiteFormModal';
import { getAllPools } from '../../../../data/pools-service';
import { deleteSite, getAllSites, getSiteCategories } from '../../../../data/sites-service';

export const SiteManagerPage: React.FC = () => {
  const [siteModalState, setSiteModalState] = useState<'CLOSED' | 'NEW' | 'EDIT'>('CLOSED');
  const [permissionsModalState, setPermissionsModalState] = useState<'CLOSED' | 'OPEN'>('CLOSED');
  const [sites, setSites] = useState<ISite[]>([]);
  const [availablePools, setAvailablePools] = useState<IPool[]>([]);
  const [availableCategories, setAvailableCategories] = useState<string[]>([]);
  const [poolNamesById, setPoolNamesById] = useState<Record<string, string>>({});
  const [selectedRows, setSelectedRows] = useState<RowNode[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [isDeleting, setIsDeleting] = useState(false);
  const filterRef = useRef<HTMLInputElement>(null);

  useWindowResizeCallback(() => gridApi?.sizeColumnsToFit());

  useEffect(() => {
    const allPoolSub = getAllPools().pipe(
      tap(pools => {
        setAvailablePools(pools);
        setPoolNamesById(pools.reduce((accum, pool) => ({
          ...accum,
          [pool.id]: pool.name
        }), {} as Record<string, string>));
      })
    ).subscribe();

    return () => allPoolSub.unsubscribe();
  }, []);

  useEffect(() => {
    const allSitesSub = getAllSites().pipe(tap(setSites)).subscribe();
    const categoriesSub = getSiteCategories().pipe(tap(setAvailableCategories)).subscribe();

    return () => {
      allSitesSub.unsubscribe();
      categoriesSub.unsubscribe();
    };
  }, [siteModalState, permissionsModalState]);

  function onDeleteSitesClick(): void {
    if (!window.confirm(`Are you sure you want to delete ${selectedRows.length} sites?`)) {
      return;
    }
    setIsDeleting(true);

    from([...selectedRows]).pipe(
      concatMap(row => {
        return deleteSite(row.data.id).pipe(
          tap(() => {
            const sliceIndex = sites.findIndex(s => s.id === row.data.id);
            if (sliceIndex > -1) {
              sites.splice(sliceIndex, 1);
              setSites([...sites]);
            }
          }),
          catchError(error => {
            console.error(error);
            return EMPTY; // todo
          })
        );
      }),
      finalize(() => setIsDeleting(false))
    ).subscribe();
  }

  function onFilterChange(event: ChangeEvent<HTMLInputElement>) {
    gridApi?.setQuickFilter(event.target.value);
  }

  return <>
    <div className="flex flex-col h-full">
      <div className="flex w-full mb-5">
        <div className="flex item-center flex-grow">
          <input ref={filterRef}
                 className="w-1/2 border-b border-solid border-black"
                 placeholder="Filter by any site field"
                 onChange={onFilterChange}
                 onKeyDown={e => {
                   if (e.key === 'Escape' && filterRef.current) {
                     filterRef.current.value = '';
                     gridApi?.setQuickFilter('');
                   }
                 }}
                 type="text"/>
        </div>
        <div className="flex justify-end flex-shrink">
          <span>
            <button {...btnPrimary} onClick={() => setSiteModalState('NEW')}>New Site...</button>
          </span>

          <If condition={selectedRows.length > 1 && !isDeleting}>
            <span className="ml-3">
              <button {...btnPrimary} onClick={() => setPermissionsModalState('OPEN')}>
                Update Access...
              </button>
            </span>
          </If>

          <If condition={selectedRows.length === 1 && !isDeleting}>
            <span className="ml-3">
              <button {...btnPrimary} onClick={() => setSiteModalState('EDIT')}>
                Edit Site...
              </button>
            </span>
          </If>

          <If condition={selectedRows.length > 0}>
            <span className={`ml-3 ${isDeleting && 'opacity-25'}`}>
              <button {...btnDanger} disabled={isDeleting} onClick={onDeleteSitesClick}>
                <Spinner condition={isDeleting}/>{isDeleting ? 'Deleting...' : 'Delete'}
              </button>
            </span>
          </If>
        </div>
      </div>
      <div className="self-stretch h-full">
        <div className="ag-theme-alpine w-full h-full">
          <AgGridReact
            rowData={sites}
            rowSelection="multiple"
            onSelectionChanged={() => setSelectedRows(gridApi?.getSelectedNodes() ?? [])}
            context={{
              poolNamesById
            }}
            gridOptions={{
              defaultColDef: {
                sortable: true,
                resizable: true
              }
            }}
            onGridReady={grid => {
              setGridApi(grid.api);
              grid.api.sizeColumnsToFit();
            }}
            immutableData={true}
            getRowNodeId={row => row.id}
            rowClass="rounded border-b border-gray-200 cursor-pointer hover:bg-gray-100 cell-outline-none"
            suppressCellSelection={true}
          >
            <AgGridColumn
              maxWidth={30}
              checkboxSelection={true}
              headerCheckboxSelection={true}
              headerCheckboxSelectionFilteredOnly={true}
            />
            <AgGridColumn field="title" sort="asc" cellRendererFramework={row =>
              <a href={`/sites/${row.data.path}`} // todo parameterize base path
                 rel="noreferrer"
                 target="_blank"
                 className="text-blue-700 font-bold text-xl mb-1 hover:underline flex items-center"
                 title={row.data.title}
              >
                {row.data.title} <ExternalLinkOutline className="ml-1"/>
              </a>
            }/>
            <AgGridColumn
              headerName="Available To"
              valueGetter={params =>
                params.data.isPublic
                  ? 'PUBLIC'
                  : Object.entries(params.data.permissions as Record<string, boolean>)
                    .filter(([ignoredPoolId, hasAccess]) => hasAccess)
                    .map(([poolId]) => params.context.poolNamesById[poolId])
                    .join(', ')
              }
              cellRendererFramework={row =>
                <span className="flex items-center">
                  <If condition={!!row.data.whitelist?.length}>
                    <span title={`Whitelist:\n${row.data.whitelist?.join('\n')}`}><LockClosedOutline className="mr-1 text-gray-600"/></span>
                  </If>
                  <span title={row.value}>{row.value}</span>
                </span>
              }
            />
            <AgGridColumn field="category"/>
            <AgGridColumn field="description" cellRendererFramework={row =>
              <span title={row.data.description}>{row.data.description}</span>}
            />
            <AgGridColumn headerName="Last Updated By" cellRendererFramework={row =>
              <div className="text-sm">
                <p className="text-gray-900">{row.data.updatedBy ?? row.data.createdBy}</p>
                <p className="text-gray-600">{row.data.updated.toLocaleString()}</p>
              </div>
            }/>
          </AgGridReact>
        </div>
      </div>
    </div>
    <If condition={siteModalState !== 'CLOSED'}>
      <SiteFormModal
        onClosed={() => setSiteModalState('CLOSED')}
        availablePools={availablePools}
        availableCategories={availableCategories}
        siteToEdit={siteModalState === 'EDIT' ? selectedRows[0]?.data : undefined}
      />
    </If>

    <If condition={permissionsModalState === 'OPEN'}>
      <BatchSitePermissionsModal
        onClosed={() => setPermissionsModalState('CLOSED')}
        availablePools={availablePools}
        selectedSites={selectedRows.map(r => r.data as ISite)}
      />
    </If>
  </>;
};
