import { useState } from 'react';
import { useLocation } from 'react-router';

export function useSearchParams(): URLSearchParams {
  const location = useLocation<string>();
  return new URLSearchParams(location.search);
}

export function useQueryParam(name: string, getDefault?: () => string): string | null {
  const [val, setVal] = useState<string | null>(null);
  const parsedParams = useSearchParams();
  let newVal = parsedParams.get(name);
  if (!newVal && getDefault) {
    newVal = getDefault();
  }
  if (val !== newVal) {
    setVal(newVal);
  }
  return val;
}

export function useQueryParamNumber(name: string, getDefault?: () => number): number | null {
  const [val, setVal] = useState<number | null>(null);
  const param = useQueryParam(name);
  let newVal = param !== null ? parseInt(param) : null;
  if (newVal === null && getDefault) {
    newVal = getDefault();
  }
  if (val !== newVal) {
    setVal(newVal);
  }

  return val;
}

export function useQueryParamDate(name: string, getDefault?: () => Date): Date | null {
  const [val, setVal] = useState<Date | null>(null);
  const param = useQueryParam(name);

  let newVal = param !== null ? new Date(param) : null;
  if (newVal === null && getDefault) {
    newVal = getDefault();
  }
  if (val?.getTime() !== newVal?.getTime()) {
    setVal(newVal);
  }

  return val;
}
