import { ILoginUrlStateParam } from '@contracts/auth/ILoginUrlStateParam';
import { IPool } from '@contracts/auth/IPool';
import { fromEvent, Observable, of, throwError } from 'rxjs';
import { catchError, map, skipWhile, take, tap } from 'rxjs/operators';
import { API_BASE_PATH } from './api-props';
import { authedRequest$, mapToResponse } from './request-helper';

export function fetchPools(email: string): Observable<IPool[]> {
  const url = `${API_BASE_PATH}/authentication?email=${email.trim().toLowerCase()}`;
  return authedRequest$(url).pipe(mapToResponse());
}

export function fetchPoolById(poolId: string, idpName?: string): Observable<IPool | undefined> {
  let url = `${API_BASE_PATH}/authentication?poolId=${poolId}`;
  if (idpName) {
    url = `${url}&idpName=${idpName}`;
  }
  return authedRequest$(url).pipe(
    mapToResponse<IPool>(),
    map(pools => pools?.[0]),
    catchError((error) => {
      if (error?.statusCode === 404) {
        return of(undefined);
      } else {
        return throwError(error);
      }
    })
  );
}

export function redirectToPoolLogin(pool: IPool, destinationPath: string, useAuthorizeUrl = false) {
  const state: ILoginUrlStateParam = {
    poolId: pool.id,
    originalUrl: destinationPath
    //nonce // todo
  };
  const encodedState = btoa(JSON.stringify(state));
  const redirectUrl = `${useAuthorizeUrl ? pool.authorizeUrl : pool.loginUrl}&state=${encodedState}`;
  console.log(`Login Redirect: ${redirectUrl}`);
  window.location.href = redirectUrl;
}

export function redirectToPoolInPopOut(pool: IPool, destinationPath: string, useAuthorizeUrl = false) {
  const state: ILoginUrlStateParam = {
    poolId: pool.id,
    originalUrl: `/login?close=true`
    //nonce // todo
  };
  const encodedState = btoa(JSON.stringify(state));
  const redirectUrl = `${useAuthorizeUrl ? pool.authorizeUrl : pool.loginUrl}&state=${encodedState}`;
  console.log(`Window Open Url: ${redirectUrl}`);

  // wait for the popout window to post a closed message.
  fromEvent(window, 'message').pipe(
    skipWhile((e) => (e as any).data !== 'CLOSE_WINDOW'),
    tap(() => window.location.href = destinationPath),
    take(1)
  ).subscribe();

  window.open(redirectUrl, 'new', "height=600,width=500")
}

export function logout(): Observable<void> {
  const url = `${API_BASE_PATH}/authentication`;
  return authedRequest$({ url, method: 'DELETE' }).pipe(mapToResponse<void>());
}
