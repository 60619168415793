import { IPool } from '@contracts/auth/IPool';
import { Observable } from 'rxjs';
import { API_BASE_PATH } from './api-props';
import { authedRequest$, mapToResponse } from './request-helper';

const POOLS_PATH = `${API_BASE_PATH}/pools`;

export function getAllPools(): Observable<IPool[]> {
  return authedRequest$(`${POOLS_PATH}?withClient=true`).pipe(
    mapToResponse()
  );
}
