import { ISiteTrafficCollection } from '@contracts/sites/ISiteTrafficCollection';
import { GridApi } from 'ag-grid-community';
import { AgGridColumn, AgGridColumnProps, AgGridReact } from 'ag-grid-react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Paginate from 'react-paginate';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { useObservable } from 'rxjs-hooks';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { useQueryParam, useQueryParamDate, useQueryParamNumber } from '../../../../components/common/hooks/queryParams';
import { useWindowResizeCallback } from '../../../../components/common/hooks/windowResize';
import { defaultPaginateProps } from '../../../../components/common/paging/default-paginate-props';
import { Spinner } from '../../../../components/common/spinner/Spinner';
import { getTraffic } from '../../../../data/operations/operations-service';
import { getAllSites } from '../../../../data/sites-service';

type TrafficData = ISiteTrafficCollection['pageData'][0];

export const SitesTrafficPage: React.FC = () => {
  const history = useHistory();
  const [now, setNow] = useState(new Date());
  const location = useLocation();
  const queryCurrentPage = useQueryParamNumber('page') ?? 0;
  const queryBefore = useQueryParamDate('before', () => now);
  const querySitePath = useQueryParam('site', () => '') ?? '';
  const queryPageSize = useQueryParamNumber('pageSize') ?? 300;

  const [gridApi, setGridApi] = useState<GridApi>();
  const [columns, setColumns] = useState<AgGridColumnProps[]>();
  const [pagesCount, setPagesCount] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSitesLoading, setIsSitesLoading] = useState(true);
  const [selectedSiteOption, setSelectedSiteOption] = useState<{ label: string, value: string }>();

  useWindowResizeCallback(() => {
    gridApi?.sizeColumnsToFit();
  });

  const traffic = useObservable((_, trafficParams$) =>
      trafficParams$.pipe(
        filter(([_, currentPage, before]) => currentPage !== null && before !== null),
        switchMap(([filter, currentPage, before]) => {
          setIsLoading(true);
          return getTraffic(filter, currentPage * queryPageSize, queryPageSize, before ?? now).pipe(
            tap(({ pageData: traffics, totalItems: totals }) => {
              if (!columns) {
                const colDefs = Object.keys(traffics?.[0] ?? {})
                  .filter(key => key !== 'id')
                  .map(key => ({
                    field: key,
                    resizable: true,
                    cellClass: classNames('px-2')
                  } as AgGridColumnProps));
                setColumns(colDefs);
              }

              setTotalItems(totals);
              setPagesCount(Math.ceil(totals / queryPageSize));
            }),
            map(response => response.pageData),
            finalize(() => setTimeout(() => setIsLoading(false), 500))
          );
        })
      )
    , [] as ISiteTrafficCollection['pageData'], [querySitePath, queryCurrentPage, queryBefore]
  );

  const siteOptions = useObservable(() =>
    getAllSites().pipe(
      tap(sites => sites.sort((a, b) => a.path.localeCompare(b.path))),
      map(sites => sites.map(site => ({ label: site.path, value: site.path }))),
      map(sites => [{ label: 'All', value: '' }, ...sites]),
      tap(sites => {
        setSelectedSiteOption(sites.find(site => site.value === querySitePath) ?? sites[0]);
      }),
      finalize(() => setIsSitesLoading(false))
    )
  );

  useEffect(() => {
    if (!location.search) {
      setNow(new Date());
      updateQueries({ before: now, replace: true });
    }
  }, [location.search]);

  function getRowId(trafficEntry: TrafficData): string {
    return trafficEntry.id;
  }

  function updateQueries(opts?: Partial<{ page: number; before: Date | null; sitePath: string; pageSize: number; replace: boolean }>): void {
    const state = {
      search: `?page=${opts?.page ?? queryCurrentPage}` +
        `&before=${(opts?.before ?? queryBefore ?? now).toISOString()}` +
        `&site=${opts?.sitePath ?? querySitePath}` +
        `&pageSize=${opts?.pageSize ?? queryPageSize}`
    };
    opts?.replace ? history.replace(state) : history.push(state);
  }

  function onSiteFilterChanged(option): void {
    setSelectedSiteOption(option);
    updateQueries({ page: 0, sitePath: option.value });
  }

  function onPageClicked(pageNumber: number): void {
    updateQueries({ page: pageNumber });
  }

  function onRefreshBefore(): void {
    setNow(new Date());
    updateQueries({ page: 0, before: now });
  }

  return <div className="h-full w-full flex flex-col">
    <div className="mb-4 flex-shrink-0 flex items-center w-full">
      <div className="flex-shrink flex items-center">
        <span className="font-semibold text-gray-700">Results as of:</span>
        <span className="ml-2">{queryBefore?.toLocaleString()}</span>
        <button type="button"
                className="ml-2 cursor-pointer font-semibold text-blue-700 hover:underline"
                onClick={onRefreshBefore}
        >
          Refresh
        </button>
      </div>
      <div className="flex-grow flex items-center justify-end">
        <Spinner condition={isLoading}/>
        <Select
          className="ml-2 w-1/3 text-lg"
          options={siteOptions ?? []}
          isSearchable={false}
          defaultValue={selectedSiteOption}
          value={selectedSiteOption}
          isLoading={isSitesLoading}
          onChange={onSiteFilterChanged}
        />
      </div>
    </div>
    <div className="self-stretch h-full">
      <div className="ag-theme-alpine h-full w-full">
        <AgGridReact
          rowData={traffic ?? []}
          immutableData={true}
          getRowNodeId={getRowId}
          rowClass="rounded border-b border-gray-200 cursor-pointer hover:bg-gray-100 cell-outline-none"
          onGridReady={grid => {
            setGridApi(grid.api);
            grid.api.sizeColumnsToFit();
          }}
          onRowDataUpdated={() => {
            gridApi?.sizeColumnsToFit();
          }}
        >
          {columns?.map(col => <AgGridColumn key={col.field} {...col}/>)}
        </AgGridReact>
      </div>
    </div>
    <div className="flex items-center justify-between flex-shrink-0 w-full pt-2">
      <span className="text-gray-600">Total Results {totalItems}</span>
      <Paginate {...defaultPaginateProps}
                pageCount={pagesCount}
                initialPage={queryCurrentPage ?? 0}
                forcePage={queryCurrentPage ?? 0}
                onPageChange={e => onPageClicked(e.selected)}/>
    </div>
  </div>;
};
