// credit https://www.peterbe.com/plog/a-darn-good-search-filter-function-in-javascript

function escapeRegExp(value: string) {
  return value.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
}

export function fancySearch(query: string, list: string[]): string[] {
  const words = query
    .split(/\s+/g)
    .map(s => s.trim())
    .filter(s => !!s);

  const hasTrailingSpace = query.endsWith(' ');

  const searchRegex = new RegExp(
    words
      .map((word, i) => {
        return i + 1 === words.length && !hasTrailingSpace
          ? `(?=.*\\b${escapeRegExp(word)})`
          : `(?=.*\\b${escapeRegExp(word)}\\b)`;
      })
      .join('') + '.+',
    "gi"
  );

  return list.filter(item => searchRegex.test(item));
}
