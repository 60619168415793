import { CheckCircleOutline, EmojiSadOutline } from 'heroicons-react';
import React from 'react';
import { Else, If, Then } from 'react-if';
import { Link } from 'react-router-dom';
import { btnPrimary } from '../components/common/button/Button';
import { useSearchParams } from '../components/common/hooks/queryParams';

export const FormResultPage: React.FC = () => {
  const queryParams = useSearchParams();
  const isSubmissionSuccessful = queryParams.get('isSuccess') !== 'false';
  const submissionId = queryParams.get('submissionId');

  const SuccessfulSubmission = () => <div className="w-full h-full flex items-center justify-center">
    <div className="text-lg">
      <div className="text-3xl flex">
        <CheckCircleOutline size={40} className="text-green-600 inline-block mr-2 flex-shrink-0"/>
        <div className="leading-8">
          <div className="flex items-baseline flex-wrap">
            <span className="text-green-600">
              Success!
            </span>&nbsp;
            <span>
              Your information has been saved.
            </span>
          </div>
          <div>
            <span className="text-sm text-gray-600">
              (ref: {submissionId})
            </span>
          </div>
        </div>
      </div>
      <div className="ml-12 mt-2 flex justify-center">
        {/*todo route constant*/}
        <Link to="/directory" type="button" {...btnPrimary}>
          <span>Done</span>
        </Link>
      </div>
    </div>
  </div>;

  const ErrorfulSubmission = () => <div className="w-full h-full flex items-center justify-center">
    <div className="text-lg">
      <div className="text-2xl flex">
        <EmojiSadOutline size={40} className="text-red-600 inline-block mr-2 flex-shrink-0"/>
        <div className="flex items-baseline flex-wrap">
          <span>
            There was an error submitting your information.&nbsp;
          </span>
          <span className="text-base text-gray-600">
            (ref: {submissionId})
          </span>
        </div>
      </div>
      <div className="ml-12 mt-2">
        Please&nbsp;
        <button
          type="button"
          className="text-blue-700 font-semibold hover:underline"
          onClick={() => window.history.back()}
        >try again later
        </button>
        &nbsp;or contact&nbsp;
        <a className="text-blue-700 font-semibold hover:underline"
           href={`mailto:coach@myblendedlearning.com?subject=Help, form submission failed. (ref: ${submissionId})&body=Please include any information or screenshots to help us diagnose your problem.`}>
          coach@myblendedlearning.com
        </a>
        &nbsp;for assistance.
      </div>
    </div>
  </div>;

  return <If condition={isSubmissionSuccessful}>
    <Then><SuccessfulSubmission/></Then>
    <Else><ErrorfulSubmission/></Else>
  </If>;
};
