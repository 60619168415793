import { GridApi } from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { ExternalLinkOutline } from 'heroicons-react';
import React, { useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { tap } from 'rxjs/operators';
import { useWindowResizeCallback } from '../../../components/common/hooks/windowResize';
import { Modal } from '../../../components/common/modal/Modal';
import { For } from '../../../components/common/react/For';
import { getPoolDetails } from '../../../data/operations/operations-service';

const PoolItem = (props: { memberships: any[], pool: any }) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  useWindowResizeCallback(() => gridApi?.sizeColumnsToFit());
  const { pool } = props;

  return <div className="ag-theme-alpine w-full h-full">
    <AgGridReact
      rowData={props.memberships}
      gridOptions={{
        defaultColDef: {
          sortable: true,
          resizable: true
        }
      }}
      enableCellTextSelection={true}
      onGridReady={grid => {
        setGridApi(grid.api);
        grid.api.sizeColumnsToFit();
      }}
      deltaRowDataMode={true}
      getRowNodeId={row => row.email}
      rowClass="shadow-sm"
    >
      <AgGridColumn
        field="identification"
        headerName="Identifier"
        cellRendererFramework={row =>
          <div>
            <a className="text-blue-700 hover:underline flex items-center "
               target="_blank"
               rel="noreferrer"
               title="View in AWS Console"
               href={`https://${pool.region}.console.aws.amazon.com/cognito/users/?region=${pool.region}#/pool/${pool.id}/users/${row.data.username}`}
            >
              {row.value}
              <ExternalLinkOutline size={20} className="ml-1"/>
            </a>
          </div>
        }/>
      <AgGridColumn field="status"/>
      <AgGridColumn field="enabled"/>
      <AgGridColumn field="username"/>
    </AgGridReact>
  </div>;
};

export const UserPoolsPage: React.FC = () => {
  const [pools, setPools] = useState<any[]>([]);
  const [showMembersOfPool, setShowMembersOfPool] = useState<any /*todo*/>(undefined);

  useEffect(() => {
    getPoolDetails().pipe(
      tap(setPools)
    ).subscribe();
  }, []);

  const FieldLabel = (props) =>
    <span className="font-bold text-gray-700">{props.children}</span>;

  return <>
    <For items={pools} render={(pool: any/*todo*/) =>
      <div key={pool.id} className="mb-12">
        <div className="text-4xl">{pool.name}</div>
        <div className="flex items-center">
          <FieldLabel>Pool Id:</FieldLabel>
          <a className="ml-2 text-blue-700 hover:underline font-bold flex items-center"
             target="_blank"
             rel="noreferrer"
             title="View in AWS Console"
             href={`https://${pool.region}.console.aws.amazon.com/cognito/users/?region=${pool.region}#/pool/${pool.id}/details`}>
            {pool.id}
            <ExternalLinkOutline size={20} className="ml-1"/>
          </a>
        </div>
        <div><FieldLabel>ARN:</FieldLabel> {pool.arn}</div>
        <div><FieldLabel>App Client Id:</FieldLabel> {pool.appClientId || 'N/A'}</div>
        <div><FieldLabel>Domain Url:</FieldLabel> {pool.domainUrl}</div>
        <div>
          <FieldLabel>Callback Urls:</FieldLabel>
          <ul className="ml-3">
            {pool.clientCallbackUrls?.split(',').map(cb => <li key={cb}>{cb.trim()}</li>) || <li>N/A</li>}
          </ul>
        </div>
        <div>
          <FieldLabel>Memberships:</FieldLabel>
          <span className="ml-2 mt-2">
            <If condition={pool.memberships?.length}>
              <Then>
                <button className="text-blue-700 font-bold hover:underline"
                        onClick={() => setShowMembersOfPool(pool)}>
                  {pool.memberships?.length ?? '0'} users
                </button>
              </Then>
              <Else>
                {pool.memberships?.length ?? '0'} users
              </Else>
            </If>
          </span>
        </div>
      </div>
    }/>
    <If condition={!!showMembersOfPool}>
      <Modal isOpen={true} onDidDismiss={() => setShowMembersOfPool(undefined)} widerModal={true}>
        <div className="flex flex-col p-5 w-full">
          <div className="text-3xl flex-shrink-0">{showMembersOfPool?.name}</div>
          <div className="w-full flex-grow">
            <If condition={showMembersOfPool?.memberships?.length > 0}>
              <Then>
                <PoolItem pool={showMembersOfPool} memberships={showMembersOfPool?.memberships}/>
              </Then>
              <Else>
                <div className="flex items-center justify-center h-full text-xl">No Memberships</div>
              </Else>
            </If>
          </div>
        </div>
      </Modal>
    </If>
  </>;
};
