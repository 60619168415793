import { IPool } from '@contracts/auth/IPool';
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { fetchPools, redirectToPoolLogin } from '../../data/login-service';
import { btnPrimary } from '../common/button/Button';

export interface IEmailStepProps {
  onNext: (email: string, pools: IPool[]) => void
}

export const EmailStep: React.FC<IEmailStepProps> = (props) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [isUserNotFound, setUserNotFound] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const location = useLocation();

  const onFormSubmit = async (event) => {
    event.preventDefault();

    setIsBusy(true);

    const email = emailInputRef.current?.value ?? '';
    const pools = await fetchPools(email).toPromise(); // todo try catch

    setIsBusy(false);

    if ((pools?.length ?? 0) === 0) {
      setUserNotFound(true);
    } else if (pools.length === 1) {
      const pool = pools[0];
      const queryParams = new URLSearchParams(location.search);
      const redirect = queryParams.get('redirect');
      redirectToPoolLogin(pool!, decodeURIComponent(redirect ?? '/'));
    } else {
      props.onNext(email, pools);
    }
  };

  return (
    <form className="flex justify-center items-center h-full"
          onSubmit={onFormSubmit}>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
        <div className="mb-4">
          <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input ref={emailInputRef}
                 className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                 required
                 id="email"
                 type="text"
                 placeholder="Email"
                 autoCapitalize="false"/>
          {
            isUserNotFound &&
            <div className="text-sm text-red-400">Sorry, we could not find a user for this email.</div>
          }
        </div>
        <div className="flex items-center justify-between">
          <button {...btnPrimary}
                  type="submit"
                  disabled={isBusy}
                  data-qa="next-btn"
          >
            {!isBusy ? <span>Next</span> : <span>Loading</span>}
          </button>
        </div>
      </div>
    </form>
  );
};
