import { IPool } from '@contracts/auth/IPool';
import React, { useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { EMPTY } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { FullScreenLoading } from '../components/common/FullScreenLoading';
import { useSearchParams } from '../components/common/hooks/queryParams';
import { EmailStep } from '../components/login/EmailStep';
import { PoolSelectStep } from '../components/login/PoolSelectStep';
import { redirectToPoolInPopOut, fetchPoolById, redirectToPoolLogin } from '../data/login-service';

export const LoginPage: React.FC = () => {
  const [state, setState] = useState({
    step: 'EMAIL' as 'EMAIL' | 'POOL_SELECT',
    email: '',
    pools: [] as IPool[]
  });
  const queryParams = useSearchParams();
  const [isLoadingPool, setIsLoadingPool] = useState(false);
  const [isRedirectingToIdp, setIsRedirectingToIdp] = useState(false);

  useEffect(() => {
    const poolId = queryParams.get('poolId');
    const idpName = queryParams.get('idpName');
    const popOutAuth = queryParams.get('popOutAuth');
    const closeWindow = queryParams.get('close');
    if (closeWindow) {
      // eslint-disable-next-line no-restricted-globals
      opener?.postMessage('CLOSE_WINDOW', "*");
      window.close();
    }

    if (poolId) {
      setIsLoadingPool(true);

      fetchPoolById(poolId, idpName ?? undefined).pipe(
        tap((pool) => {
          if (!pool) {
            console.error(`Could not find pool by id ${poolId}`)
            throw new Error(`Pool by id ${poolId} not found.`);
          }

          const redirect = queryParams.get('redirect') ?? '/'; // todo extract to enum in contracts
          const hasIdpName = Boolean(idpName);

          setIsRedirectingToIdp(true);
          if (popOutAuth) {
            redirectToPoolInPopOut(pool, decodeURIComponent(redirect), hasIdpName)
          } else {
            redirectToPoolLogin(pool, decodeURIComponent(redirect), hasIdpName);
          }
        }),
        catchError(err => {
          console.error(`Unexpected error fetching pool by id ${poolId}`, err);
          return EMPTY;
        }),
        finalize(() => setIsLoadingPool(false))
      ).subscribe();
    } else if (idpName) {
      console.error(`"idpName" query param requires a "poolId" param to also be set.`);
    }
  }, []);

  const onNext = (email: string, pools: IPool[]): void => {
    setState({ step: 'POOL_SELECT', email, pools });
  };

  const onSignIn = (selectedPoolId: string): void => {
    const pool = state.pools.find(pool => pool.id === selectedPoolId);
    const redirect = queryParams.get('redirect');
    redirectToPoolLogin(pool!, decodeURIComponent(redirect ?? '/'));
  };

  return <If condition={!isLoadingPool && !isRedirectingToIdp}>
    <Then>
      {{
        'EMAIL': <EmailStep onNext={onNext}/>,
        'POOL_SELECT': <PoolSelectStep email={state.email} onPoolSelect={onSignIn} pools={state.pools}/>
      }[state.step]}
    </Then>
    <Else>
      <FullScreenLoading/>
    </Else>
  </If>;
};
