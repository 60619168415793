import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { If, Then } from 'react-if';
import { btnPrimary } from '../button/Button';

interface IFileDropInputProps {
  dropZoneOptions: DropzoneOptions;
  onFiles: (files: File[]) => void;
  multiple?: boolean
}

export const FileDropInput: React.FC<IFileDropInputProps> = (props) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    ...props?.dropZoneOptions,
    onDrop: props?.onFiles
  });

  const files = (acceptedFiles as Array<File & { path: string }>).map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} multiple={props.multiple}
               type="file"
               //@ts-ignore
               directory=""
               webkitdirectory=""
        />
        <If condition={files?.length === 0}>
          <Then>
            <span className="text-xl">Select the folder that contains the index.html file for this site.</span>
            <div>
              <button {...btnPrimary} type="button">Upload</button>
            </div>
          </Then>
        </If>
      </div>
      <aside>
        <If condition={files?.length > 0}>
          <ul>Found {files.length} Files</ul>
        </If>
      </aside>
    </section>
  );
};

export default FileDropInput;
