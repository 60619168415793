import { IPool } from '@contracts/auth/IPool';
import { ISite } from '@contracts/sites/ISite';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { If, Then } from 'react-if';
import { EMPTY } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { savePermissions } from '../../data/sites-service';
import { btnPrimary, btnSecondary } from '../common/button/Button';
import { Modal } from '../common/modal/Modal';
import { For } from '../common/react/For';

export interface IBatchSitePermissionModalProps {
  selectedSites: ISite[]
  availablePools: IPool[];

  onClosed(): void;
}

export const BatchSitePermissionsModal = (props: IBatchSitePermissionModalProps) => {
  type Step = 'PERMISSIONS' | 'REVIEW';
  const steps: Step[] = ['PERMISSIONS', 'REVIEW'];
  const [step, setStep] = useState<Step>(steps[0]);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [effectValue, setEffectValue] = useState<'ALLOW' | 'DENY'>('ALLOW');
  const [poolSelection, setPoolSelection] = useState<Record<string, boolean>>(
    props.availablePools.reduce((accum, pool) => ({ ...accum, [pool.id]: false }), {})
  );
  const [isSaving, setIsSaving] = useState(false);

  function onCancel() {
    setIsModalOpen(false);
    props.onClosed();
  }

  function onSaveClick() {
    const isDeny = effectValue === 'DENY';
    const permsToSave = {};
    for (const poolId in poolSelection) {
      // noinspection JSUnfilteredForInLoop
      if (poolSelection[poolId]) {
        permsToSave[poolId] = !isDeny;
      }
    }

    setIsSaving(true);
    savePermissions(props.selectedSites, permsToSave).pipe(
      tap(() => setIsModalOpen(false)),
      catchError(() => {
        alert(`Error updating permissions. See console via ctrl + j (command + j on mac)`);
        return EMPTY;
      }),
      finalize(() => setIsSaving(false))
    ).subscribe();
  }

  function onFormSubmit() {
    // eslint-disable-next-line no-restricted-globals
    event?.preventDefault();
    setStep('REVIEW');
  }

  function onBackClick() {
    setStep('PERMISSIONS');
  }

  const PermissionsStep = () => {
    const canContinue = useMemo(() => {
      const selectedPools = Object.values(poolSelection).filter(Boolean);
      return selectedPools.length > 0;
    }, []);

    function onEffectChanged(e: ChangeEvent<HTMLInputElement>) {
      setEffectValue(e.target.value as 'ALLOW' | 'DENY');
    }

    return <div className="w-full py-5">
      <form className="flex flex-col h-full" onSubmit={onFormSubmit}>
        <div className="flex-none px-5">
          <h2 className="text-4xl">Update Site Access</h2>
        </div>

        <div className="flex-grow flex flex-col mt-4 px-5 overflow-y-auto">
          <div className="flex flex-col">
            <div className="flex flex-col text-xl text-gray-700">
              <label className="cursor-pointer">
                <input type="radio"
                       name="effect"
                       value="ALLOW"
                       checked={effectValue === 'ALLOW'}
                       onChange={onEffectChanged}/>
                <span className="ml-2 hover:underline">Add Access</span>
              </label>

              <label className="cursor-pointer mt-1">
                <input type="radio"
                       name="effect"
                       value="DENY"
                       checked={effectValue === 'DENY'}
                       onChange={onEffectChanged}/>
                <span className="ml-2 hover:underline">Remove Access</span>
              </label>
            </div>

            <div className="text-2xl mt-4">User Pools</div>
            <ul className="mt-2">
              <For items={props.availablePools} render={pool =>
                <li className="mb-2 cursor-pointer" key={pool.id}>
                  <label className="flex items-center cursor-pointer text-gray-700 hover:underline"
                         htmlFor={`pool-select-${pool.name}`}>
                    <input type="checkbox"
                           id={`pool-select-${pool.name}`}
                           checked={poolSelection[pool.id]}
                           onChange={e => setPoolSelection({ ...poolSelection, [pool.id]: e.target.checked })}
                    />
                    <span className="flex items-center ml-3">{pool.name} ({pool.id})</span>
                  </label>
                </li>
              }/>
            </ul>
          </div>
        </div>

        <div className="flex-none flex justify-end mt-4 px-5">
          <span>
            <button {...btnSecondary} type="button" onClick={onCancel}>Cancel</button>
          </span>
          <span className={`ml-2 ${!canContinue && 'opacity-50'}`}>
            <button {...btnPrimary} type="submit" disabled={!canContinue}>Review</button>
          </span>
        </div>
      </form>
    </div>;
  };

  const ReviewStep = () => {
    const isDeny = effectValue === 'DENY';
    const selectedPools =
      Object.entries(poolSelection ?? [])
        .filter(([poolId, isSelected]) => isSelected)
        .map(([poolId]) => props.availablePools.find(p => p.id === poolId)) as IPool[];

    return <div className="flex flex-col h-full w-full py-5">
      <div className="flex-none px-5">
        <h2 className="text-4xl">Review Site Access</h2>
        <p className="mt-3 text-xl text-gray-700">
          Access to<span className="font-semibold"> {props.selectedSites.length} </span>sites <span
          className={`font-bold text-${(isDeny ? 'red' : 'green')}-600`}>
            will be {isDeny ? 'removed' : 'added'}
          </span> for the following pools.
        </p>
      </div>
      <div className="flex-grow flex flex-col mt-4 px-5 overflow-y-auto">
        <div className="flex flex-col">
          <div className="text-2xl">Pools {isDeny ? 'Losing Access' : 'Gaining Access'}</div>
          <ul className="mt-2">
            <For items={selectedPools} render={pool =>
              <li className="ml-2" key={pool.id}>
                <span className="text-lg text-gray-700">{pool.name}</span>
              </li>
            }/>
          </ul>

          <div className="mt-3 text-2xl">Selected Sites</div>
          <ul className="ml-2 mt-2">
            <For items={props.selectedSites} render={site =>
              <li className="mb-2" key={site.id}>
                <div className="text-lg text-gray-700">{site.title}</div>
                <div className="ml-3 text-xs">
                  <span className="font-semibold">
                    Path:
                  </span> <a href={`/sites/${site.path}`}
                             className="text-blue-700 hover:underline"
                             rel="noreferrer"
                             target="_blank">{site.path}</a>
                  <If condition={!!site.category}>
                    <Then>
                      <br/>
                      <span className="font-semibold">Category:</span> {site.category}
                    </Then>
                  </If>
                </div>
              </li>
            }/>
          </ul>
        </div>
      </div>

      <div className="flex-none flex items-center justify-end mt-4 px-5">
        <If condition={props.selectedSites.length * selectedPools.length > 1000}>
          <div className="text text-gray-800 leading-5">
            <span className="font-semibold">Note:</span> There are over a thousand permissions to update. This may take
            some time to save.
          </div>
        </If>
        <span className={`${isSaving && 'opacity-25'}`}>
          <button {...btnSecondary} type="button" disabled={isSaving} onClick={onBackClick}>Back</button>
        </span>
        <span className={`ml-2 ${isSaving && 'opacity-25'}`}>
          <button {...btnPrimary}
                  type="button" disabled={isSaving}
                  onClick={onSaveClick}>
            {isSaving ? 'Saving...' : 'Save'}
          </button>
        </span>
      </div>
    </div>;
  };

  return (
    <Modal
      cssClass="absolute"
      isOpen={isModalOpen}
      onDidDismiss={() => onCancel()}
      backdropDismiss={!isSaving}
      keyboardClose={!isSaving}
    >
      {{
        'PERMISSIONS': <PermissionsStep/>,
        'REVIEW': <ReviewStep/>
      }[step]}
    </Modal>
  );
};
