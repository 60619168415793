import { ISite } from '@contracts/sites/ISite';
import { EmojiSadOutline, ExternalLinkOutline } from 'heroicons-react';
import React, { useContext, useEffect, useState } from 'react';
import { Else, If, Then } from 'react-if';
import { finalize, tap } from 'rxjs/operators';
import { UlFor } from '../../components/common/react/For';
import { Spinner } from '../../components/common/spinner/Spinner';
import { getMySites } from '../../data/sites-service';
import { UserSessionContext } from '../../UserSessionContext';

export const SiteItem = ({ site }: { site: ISite }) => {
  return <>
    <div className="flex flex-col">
      <a href={`/sites/${site.path}`}
         rel="noreferrer"
         target="_blank"
         className="flex items-baseline text-4xl text-blue-800 hover:underline"
      >
        <span>{site.title}</span><ExternalLinkOutline className="inline-block ml-3"/>
      </a>
      <div className="text-xl">
        {site.description}
      </div>
      <div className="text text-gray-600">{site.created ? new Date(site.created).toDateString() : ''}</div>
    </div>
  </>;
};

export const SiteDirectoryPage: React.FC = () => {
  const [sites, setSites] = useState<ISite[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const userSessionContext = useContext(UserSessionContext);

  useEffect(() => {
    const sub = getMySites().pipe(
      tap(setSites),
      finalize(() => setIsLoading(false))
    ).subscribe();
    return () => sub.unsubscribe();
  }, []);

  return <If condition={!isLoading}><Then>
    <UlFor
      items={sites}
      noItemsRender={
        <div className="w-full h-full flex items-center justify-center">
          <div className="text-lg">
            <div className="text-2xl flex">
              <EmojiSadOutline size={40} className="inline-block mr-2 flex-shrink-0"/>
              <span className="">
                There are no links available for {userSessionContext.userInfo?.identification ?? 'this user'} yet.
              </span>
            </div>
            <div className="ml-12 mt-2">
              Please try again later or contact&nbsp;
              <a className="text-blue-700 font-semibold hover:underline"
                 href="mailto:coach@myblendedlearning.com?subject=Help, my directory listing is empty.">coach@myblendedlearning.com</a>
              &nbsp;for assistance.
            </div>
          </div>
        </div>
      }
      render={site =>
        <li key={site.id} className="mb-10">
          <SiteItem site={site}/>
        </li>
      }
    />
  </Then><Else>
    <div className="w-full h-full flex items-center justify-center text-3xl">
      <Spinner/>
      Loading...
    </div>
  </Else></If>;
};
