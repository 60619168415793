import classNames from 'classnames';
import { ChevronLeft, ChevronRight } from 'heroicons-react';
import React from 'react';

export const defaultPaginateProps = {
  containerClassName: classNames('flex'),
  previousLabel: (
    <div
      className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
      <span className="sr-only">Previous</span>
      <ChevronLeft className="h-5 w-5" aria-hidden="true"/>
    </div>
  ),
  nextLabel: (
    <div
      className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
      <span className="sr-only">Next</span>
      <ChevronRight className="h-5 w-5" aria-hidden="true"/>
    </div>
  ),
  breakLabel: (
    <span
      className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
    ...
    </span>
  ),
  pageLinkClassName: classNames('w-full px-4 py-2'),
  pageClassName: classNames('bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center border text-sm font-medium'),
  activeClassName: classNames('z-10 bg-blue-50 border-blue-500 text-blue-600 relative inline-flex items-center border text-sm font-medium'),
  marginPagesDisplayed: 1,
  pageRangeDisplayed: 4
};
