import { LogoutOutline, MenuOutline, UserCircle } from 'heroicons-react';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { If } from 'react-if';
import { useHistory, NavLink } from 'react-router-dom';
import { tap } from 'rxjs/operators';
import { useOutsideClick } from '../../components/common/hooks/outsideClick';
import { For } from '../../components/common/react/For';
import { logout } from '../../data/login-service';
import { UserSessionContext } from '../../UserSessionContext';

export interface INavItem {
  displayText: string;
  path: string;
  subNavItems?: INavItem[];
  icon?: JSX.Element
}

export const Authed: React.FC<{ navItems: INavItem[] }> = (props) => {
  const [state, setState] = useState({ isMenuOpen: false });
  const navRef = useRef<HTMLElement>(null);
  const history = useHistory();
  const userSession = useContext(UserSessionContext);
  const [cookies] = useCookies(['app.currentPoolId']); // TODO move to reusable common place with server
  const currentPool = useMemo(() => {
    return userSession.userInfo?.memberships.find(memb => memb.id === cookies['app.currentPoolId']);
  }, [userSession.userInfo?.memberships, cookies]);

  useOutsideClick(navRef, () => {
    if (state.isMenuOpen) {
      setState({ ...state, isMenuOpen: false });
    }
  });

  function onLogoutClick() {
    logout().pipe(
      tap(() => history.push('/login'))
    ).subscribe();
  }

  return <>
    <div className="h-screen w-screen flex">
      <nav ref={navRef}
           className={`
             flex flex-col
             flex-shrink-0
             pl-5 pt-5 pr-5
             h-full
             ${state.isMenuOpen && 'w-3/4'} md:w-auto
             ${state.isMenuOpen && 'absolute'} md:relative
             max-w-xs
             shadow-lg
             bg-white
             z-10`}
      >
        <div className="mb-2">
          <MenuOutline size={30} className="md:hidden cursor-pointer hover:shadow"
                       onClick={() => setState({ ...state, isMenuOpen: !state.isMenuOpen })}/>
        </div>

        <div className={`${!state.isMenuOpen && 'hidden'} md:block h-full`}>
          <div className="flex flex-col flex-grow h-full">
            <div className="flex items-center mb-4 w-full">
              <UserCircle size={44} className="flex-shrink-0 -ml-1 text-gray-500"/>
              <div className="flex flex-col ml-1 overflow-x-hidden">
                <span className="font-medium text-gray-700 tracking-wide truncate"
                      title={userSession.userInfo?.identification}>
                  {userSession.userInfo?.identification}
                </span>
                <span className="text-sm font-light text-gray-500"
                      title={currentPool?.name}>
                  {currentPool?.name}
                </span>
              </div>
            </div>

            <ul className="flex-grow">
              <For items={props.navItems} render={(navItem, i) =>
                <li key={i} className="mb-2">
                  <NavLink to={navItem.path} className="text-4xl hover:underline flex">
                    <span>{navItem.icon}</span><span>{navItem.displayText}</span>
                  </NavLink>
                  <If condition={Boolean(navItem.subNavItems)}>
                    <ul className="pl-4 mt-2">
                      <For items={navItem.subNavItems} render={(subNavItem, j) =>
                        <li key={j}>
                          <NavLink to={subNavItem.path}
                                   activeClassName="underline"
                                   className="mt-1 text-2xl text-gray-800 hover:underline flex items-center">
                            <If condition={!!subNavItem.icon}>
                              <span className="mr-2">{subNavItem.icon}</span>
                            </If>
                            <span>{subNavItem.displayText}</span>
                          </NavLink>
                        </li>
                      }/>
                    </ul>
                  </If>
                </li>
              }/>
            </ul>

            <div className="flex justify-center my-6">
              <button className="flex items-center text-xl text-blue-700 font-semibold hover:underline"
                      type="button"
                      onClick={onLogoutClick}
              >
                <span>Logout</span>
                <LogoutOutline className="ml-1"/>
              </button>
            </div>
          </div>
        </div>

      </nav>

      <article className="flex-initial flex-grow overflow-y-auto px-10 ml-5 py-5 break-words"
               style={{ 'wordBreak': 'break-word' }}>
        {props.children}
      </article>
    </div>
  </>;
};
