import { ISiteTrafficCollection } from '@contracts/sites/ISiteTrafficCollection';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { API_BASE_PATH } from '../api-props';
import { authedRequest$, mapToResponse } from '../request-helper';

const OPERATIONS_BASE_PATH = `${API_BASE_PATH}/operations`;
const POOLS_PATH = `${OPERATIONS_BASE_PATH}/pools`;
const TOOLS_PATH = `${OPERATIONS_BASE_PATH}/tools`;
const CACHE_TOOL_PATH = `${TOOLS_PATH}/cache`;
const POOL_INDEX_PATH = `${TOOLS_PATH}/pool-index`;
const SITES_TRAFFIC_PATH = `${OPERATIONS_BASE_PATH}/traffic`;
const DNS_PATH = (addrs?: string[]) => `${TOOLS_PATH}/dns?${addrs?.map(addr => `address=${addr}`).join('&')}`

export function getPoolDetails(): Observable<any /*todo*/> {
  return authedRequest$({ url: POOLS_PATH, method: 'GET' }).pipe(
    retry(1),
    mapToResponse<any /* todo*/>()
  );
}

export function clearCache(): Observable<void> {
  return authedRequest$({ url: CACHE_TOOL_PATH, method: 'DELETE' }).pipe(
    mapToResponse<any /* todo*/>()
  );
}

export function indexPools(): Observable<void> {
  return authedRequest$({ url: POOL_INDEX_PATH, method: 'POST' }).pipe(
    mapToResponse<any /* todo*/>()
  );
}

export function getTraffic(sitePath?: string, start = 0, count = 500, before?: string | Date): Observable<ISiteTrafficCollection> {
  const beforeDT = typeof before === 'string' ? before : (before ?? new Date()).toISOString();
  return authedRequest$({
    url: `${SITES_TRAFFIC_PATH}?start=${start}&count=${count}&beforeDT=${beforeDT}&sitePath=${sitePath ?? ''}`,
    method: 'GET'
  }).pipe(mapToResponse());
}

export function dnsTest(items?: string[] | undefined): Observable<Record<string, string[] | undefined>> {
  return authedRequest$(DNS_PATH(items))
    .pipe(mapToResponse());
}
