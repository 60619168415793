import React from 'react';
import { Else, If, Then } from 'react-if';

export interface IForProps<T> {
  items?: T[];
  render: (item: T, i: number) => JSX.Element;
  noItemsRender?: JSX.Element;
  listContainerComponent?: React.ComponentType
}

export function For<T>(props: IForProps<T>): JSX.Element {
  const ListContainer = props.listContainerComponent ?? (props => <>{props.children}</>);
  return <If condition={(props.items?.length ?? 0) <= 0 && !!props.noItemsRender}>
    <Then>
      {props.noItemsRender}
    </Then>
    <Else>
      <ListContainer>
        {props.items?.map((item, i) => props.render(item, i))}
      </ListContainer>
    </Else>
  </If>;
}

export function UlFor<T>(props: Omit<IForProps<T>, 'listContainerComponent'>): JSX.Element {
  return <If condition={(props.items?.length ?? 0) <= 0 && !!props.noItemsRender}>
    <Then>
      {props.noItemsRender}
    </Then>
    <Else>
      <ul>
        {props.items?.map((item, i) => props.render(item, i))}
      </ul>
    </Else>
  </If>;
}

export function OlFor<T>(props: Omit<IForProps<T>, 'listContainerComponent'>): JSX.Element {
  return <If condition={(props.items?.length ?? 0) <= 0 && !!props.noItemsRender}>
    <Then>
      {props.noItemsRender}
    </Then>
    <Else>
      <ol>
        {props.items?.map((item, i) => props.render(item, i))}
      </ol>
    </Else>
  </If>;
}
