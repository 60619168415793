import { EMPTY, Observable, pipe, throwError } from 'rxjs';
import { ajax as rxAjax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { userSession$ } from './user-session';

export const authedRequest$ = (options: string | AjaxRequest): Observable<AjaxResponse> => {
  const opts: AjaxRequest = typeof options !== 'string' ? options : { url: options, method: 'GET' };
  return rxAjax({
    ...opts,
    headers: {
      'Content-Type': 'application/json', // default to assuming we're always dealing with json
      ...(opts.headers ?? {})
    }
  }).pipe(
    catchError<AjaxResponse, Observable<AjaxResponse | never>>((err) => {
      if (err?.status === 401) {
        userSession$.next(undefined);
        return EMPTY;
      }

      return throwError(err);
    })
  );
};

export function mapToResponse<T>() {
  return pipe(
    map((resp: AjaxResponse) => resp.response as T)
  );
}
