import { ExternalLinkOutline } from 'heroicons-react';
import React, { useState } from 'react';
import { If } from 'react-if';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { btnPrimary } from '../../../components/common/button/Button';
import { Spinner } from '../../../components/common/spinner/Spinner';
import { clearCache, indexPools } from '../../../data/operations/operations-service';

export const ToolsPage: React.FC = () => {
  const [hasClearedCache, setHasClearedCache] = useState<boolean>(false);
  const [cacheClearedTime, setCacheClearedTime] = useState<Date>();

  const [indexedPoolTime, setIndexedPoolTime] = useState<Date>();
  const [isIndexing, setIsIndexing] = useState<boolean>(false);

  const onClearCacheClick = () => {
    setHasClearedCache(false);
    clearCache().pipe(
      tap(() => {
        setHasClearedCache(true);
        setCacheClearedTime(new Date());
      }),
      catchError(err => {
        alert(`Error clearing cache: ${err.message ?? err}`);
        return EMPTY;
      })
    ).subscribe();
  };

  const onIndexPoolsClick = () => {
    setIsIndexing(true);
    indexPools().pipe(
      tap(() => {
        setIndexedPoolTime(new Date());
      }),
      catchError(err => {
        alert(`Error starting pool indexing: ${err.message ?? err}`);
        return EMPTY;
      }),
      tap(() => setIsIndexing(false))
    ).subscribe();
  };

  const SimpleToolItem: React.FC<{ title: string; description: string; }> = (props) => {
    return <div className="w-1/2">
      <h3 className="text-3xl">
        {props.title}
      </h3>
      <p className="mt-3 text-l text-gray-700">
        {props.description}
      </p>
      <div className="mt-2">
        {props.children}
      </div>
    </div>;
  };

  return <>
    <ul>
      <li>
        <SimpleToolItem
          title="App Container Cache"
          description="The App Container maintains a simple key/value cache in memory that stores things like User Pool info and Site Permissions. This tool empties the cache."
        >
          <a href="/api/v1/operations/tools/cache" target="_blank">
            <button {...btnPrimary} >View Cache <ExternalLinkOutline className="inline"/></button>
          </a>

          <span className="ml-3">
            <button {...btnPrimary} onClick={onClearCacheClick}>Clear Cache</button>
            <If condition={hasClearedCache}>
              <span className="ml-3 text">Cache Flush Published ({cacheClearedTime?.toLocaleString()})</span>
            </If>
          </span>
        </SimpleToolItem>
      </li>

      <li className="mt-10">
        <SimpleToolItem
          title="Index User Pools"
          description="The application keeps track of users and pools by making AWS SDK calls to Cognito to get the latest user membership information and storing it in the app's sql database. This tool refreshes the database with the latest membership data from Cognito."
        >
          <button {...btnPrimary} onClick={onIndexPoolsClick} disabled={isIndexing}>
            Index Pools <span className="ml-3"><Spinner condition={isIndexing} lighter={true}/></span>
          </button>
          <If condition={!!indexedPoolTime}>
            <span className="ml-3 text">Indexing done ({indexedPoolTime?.toLocaleString()}).</span>
          </If>
        </SimpleToolItem>
      </li>

      <li className="mt-10">
        <SimpleToolItem
          title="View Site Traffic"
          description="This api shows user visits to .html pages for sites. (Note: there is a bug with the database ORM where not all results may show)">
          <a href="/api/v1/operations/traffic?start=0&count=500&beforeDT=" target="_blank">
            <button {...btnPrimary} >View Traffic <ExternalLinkOutline className="inline"/></button>
          </a>
        </SimpleToolItem>
      </li>

      <li className="mt-10">
        <SimpleToolItem
          title="View Site Form Submissions"
          description="This api shows site user form submissions. (Note: there is a bug with the database ORM where not all results may show)">
          <a href="/api/v1/operations/form-submissions?start=0&count=500&beforeDT=" target="_blank">
            <button {...btnPrimary}>View Submissions <ExternalLinkOutline className="inline"/></button>
          </a>
        </SimpleToolItem>
      </li>
    </ul>
  </>;
};
