import { IonContent, IonModal } from '@ionic/react';
import React from 'react';
import './Modal.css';

export interface IModalProps extends React.ComponentProps<typeof IonModal> {
  /** only use this for internal tooling that will only be used on a wide desktop monitor */
  widerModal?: boolean;
}

export const Modal: React.FC<IModalProps> = (props) => {
  return (
    <IonContent>
      <IonModal cssClass={`rounded ${props.widerModal && 'wider-modal'}`} {...props}>
        <div className="rounded overflow-y-auto h-full flex">
          {props.children}
        </div>
      </IonModal>
    </IonContent>
  );
};
