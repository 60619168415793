import React, { useState } from 'react';
import { ComponentWithConditionProps, If } from 'react-if';
import { addToPlayground } from '../../playground/addToPlayground';

export const Spinner: React.FC<{ condition?: ComponentWithConditionProps['condition']; className?: string; colorClass?: string; lighter?: boolean}> = (props) => {
  const color = props.colorClass ?? (props.lighter ? 'text-white' : 'text-gray-700')
  return <If condition={props?.condition ?? true}>
    <svg className={`animate-spin inline-block -ml-1 mr-3 h-5 w-5 ${color} ${props.className}`}
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24">
      <circle className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
      />
      <path className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  </If>;
};

addToPlayground({
  name: Spinner.name,
  cases: [
    { title: 'Default', render: () => <Spinner/> },
    {
      title: 'Conditional Render', render: function ConditionalRender() {
        const [toggle, setToggle] = useState(true);
        return <>
          <button type="button" onClick={() => setToggle(!toggle)}>Click me to toggle</button>
          <Spinner condition={toggle}/>
        </>
      }
    }
  ]
});
