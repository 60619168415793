import React, { useContext } from 'react';
import { Else, If, Then } from 'react-if';
import { useLocation } from 'react-router';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { UserSessionContext } from '../../UserSessionContext';

export const AuthGuardedRoute: React.FC<RouteProps> = (props) => {
  const { component, render, ...routeProps } = props;
  const { isLoading, userInfo } = useContext(UserSessionContext);
  const location = useLocation();
  return <Route {...routeProps} render={(renderProps) =>
    <If condition={!isLoading && !!userInfo}>
      <Then>
        {component ?? render?.(renderProps) ?? props.children}
      </Then>
      <Else>
        <Redirect to={`/login${location.search ?? ''}`}/>
      </Else>
    </If>
  }/>;
};
