import { INewSiteRequest, ISite } from '@contracts/sites/ISite';
import axios from 'axios';
import { Observable, of, throwError } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, map, mapTo } from 'rxjs/operators';
import { API_BASE_PATH } from './api-props';
import { authedRequest$, mapToResponse } from './request-helper';

const SITES_PATH = `${API_BASE_PATH}/sites`;
const SITE_PATH = `${SITES_PATH}/:id`;
const DIRECTORY_PATH = `${API_BASE_PATH}/directory`;
const DIRECTORY_SITES_PATH = `${DIRECTORY_PATH}/sites`;
const CATEGORIES_PATH = `${API_BASE_PATH}/site-categories`;
const SITE_PERMISSION_PATH = `${SITE_PATH}/permissions`

export function saveSite(site: ISite | INewSiteRequest): Observable<{ result: 'SUCCESS'; site: ISite; } |
  { result: 'BAD_FIELDS'; fields: { [key: string]: { displayReason: string } } } // todo move to common contracts
  > {
  const siteId: string | undefined = (site as ISite).id;
  return authedRequest$({
    url: siteId ? SITE_PATH.replace(':id', siteId) : SITES_PATH,
    method: siteId ? 'PUT' : 'POST',
    body: site
  }).pipe(
    mapToResponse<ISite>(),
    map((site: ISite) => {
      return { result: 'SUCCESS', site: site };
    }),
    catchError((err: AjaxError) => {
      if (err.status === 400) {
        return of({ result: 'BAD_FIELDS', fields: err.response.fields } as any/*todo*/);
      } else {
        return throwError(err);
      }
    })
  );
  // todo error handling
}

export function getAllSites(): Observable<ISite[]> {
  return authedRequest$({ url: SITES_PATH }).pipe(
    mapToResponse()
  );
}

export function getSiteCategories(): Observable<string[]> {
  return authedRequest$({ url: CATEGORIES_PATH }).pipe(
    mapToResponse()
  );
}

export function uploadFile(siteId: string, files: File[]): Observable<number> {
  const url = `${SITE_PATH}/content`.replace(':id', siteId);
  const cancelToken = axios.CancelToken.source();

  const formData = new FormData();
  for (const file of files) {
    const relativePath = (file as any).webkitRelativePath;
    const path = relativePath.substring(relativePath.indexOf('/') + 1);
    formData.append(path, file);
  }
  return new Observable<number>(subscriber => {
    axios.post(url, formData, {
      onUploadProgress: (progress: { total: number; loaded: number; }) => {
        if (progress.total > 0) {
          subscriber.next(Math.floor((progress.loaded / progress.total) * 100));
        }

        if (progress.loaded === progress.total) {
          subscriber.next(100);
        }
      },
      cancelToken: cancelToken.token
    })
      .catch(error => subscriber.error(error))
      .finally(() => subscriber.complete());

    return () => cancelToken.cancel();
  });
}

export function deleteSite(siteId: string): Observable<void> {
  return authedRequest$({
    url: SITE_PATH.replace(':id', siteId),
    method: 'DELETE'
  }).pipe(
    mapTo(undefined)
  );
}

export function getMySites(): Observable<ISite[]> {
  return authedRequest$(DIRECTORY_SITES_PATH).pipe(
    mapToResponse()
  );
}

export function savePermissions(sites: ISite[], permissions: Record<string, boolean>): Observable<void> {
  return authedRequest$({
    url: SITE_PERMISSION_PATH.replace(':id', '_'),
    method: 'PATCH',
    body: sites.map(site => ({ id: site.id, permissions: permissions }))
  }).pipe(mapTo(undefined));
}
